// @flow

import React from 'react'
import { Row, Col, Button } from 'antd'

import './index.scss'

function Page404() {
  return (
    <>
      <div className="wrapper">
        <p className="error">404</p>
        <p className="text">Page was not found</p>
      </div>
      <div className="wrapper-2">
        <p className="error-background">ERROR</p>
      </div>
    </>
  )
}

export default Page404
