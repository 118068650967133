// @flow

import React from 'react'
import { Row, Col } from 'antd'

import './main-image.scss'
import 'containers/app/index.scss'
import banner from 'images/optionq-banner.png'
import bannerMobile from 'images/optionq-banner-mobile.png'
import animation from 'images/banner-animation.mp4'
import animationMobile from 'images/banner-animation-mobile.mp4'

function VideoBanner(props: Object) {
  const { css, image, logo, alt1, alt2, main } = props
  return (
    <>
      <video autoPlay className="banner-animation hidden-md" muted playsInline poster={bannerMobile}>
        <source src={animationMobile} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <video autoPlay className="banner-animation hidden-xs" muted playsInline poster={banner}>
        <source src={animation} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </>
  )
}

export default VideoBanner
