// @flow

import React, { useState, useEffect } from 'react'
import { Drawer, Row, Col, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import './header.scss'
import icon from 'images/oq-icon.jpg'
import fb from 'images/fb-black.png'
import burger from 'images/burger.png'
import close from 'images/close.png'

function Header() {
  const history = useHistory()

  const [state, setState] = useState(
    {
      visible: false,
      icon: burger,
      logo: true,
      servicesRedirect: '#services'
    }
  );

  const drawerVisibility = () => {
    let servicesRedirect = '#services';
    const visible = state.visible === true ? false : true;
    const icon = state.icon === burger ? close : burger;
    const logo = state.logo === true ? false : true;
    if (history.location.pathname === '/contacts') {
      servicesRedirect = '/#services'
    }
    setState({
      visible,
      icon,
      logo,
      servicesRedirect
    });
  };

  return (
    <header>
      <Row className="header-wrapper">
        <Col id="logo" md={{ span: 12 }} xs={{ span: 12, offset: 0 }}>
          <a className="option-title" href="/">
            {state.logo && (
              <img alt="option q logo" className="icon" src={icon} />
            )}
          </a>
        </Col>
        <Col className="burger-menu-wrapper" id="burger-menu" md={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
          <a onClick={()=> drawerVisibility()}>
            <img src={state.icon} width="30" />
          </a>
        </Col>
      </Row>
      <Drawer
          closable={false}
          placement="right"
          visible={state.visible}
          width="100%"
        >
        <Row className="menu-drawer">
          <ul>
            <li>
              <a className="underline" href="/" onClick={()=> drawerVisibility()}>Home</a>
            </li>
            <li>
              <a className="underline" href={state.servicesRedirect} onClick={()=> drawerVisibility()}>Services</a>
            </li>
            <li>
              <a className="underline" href="/contacts" onClick={()=> drawerVisibility()}>Contacts</a>
            </li>
          </ul>
        </Row>
      </Drawer>
    </header>
  )
}

export default Header
