// @flow

import React from 'react'
import { Row, Col } from 'antd'
import ContactsComponent from 'components/contacts'

function Contacts() {
  return (
    <section>
      <ContactsComponent />
    </section>
  )
}

export default Contacts
