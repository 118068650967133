// @flow

import React from 'react'
import { Row, Col } from 'antd'

import Page404 from 'components/404'

function NotFound() {
  return (
    <section className="not-found">
      <Page404 />
    </section>
  )
}

export default NotFound
