// @flow

import React from 'react'
import { Row, Col, Card } from 'antd'
import ServiceCard from './service-card'
import desktop from 'images/desktop.png'
import server from 'images/server.png'
import sitemap from 'images/sitemap.png'
import chart from 'images/chart.png'
import brackets from 'images/brackets.png'
import setting from 'images/setting.png'

import './services.scss'

function Services() {
  return (
    <Row>
      <Col span={24}>
        <span className="services-title">Services</span>
        <Row className="cards-wrapper">
          <Col md={8} sm={24} xs={24}>
            <ServiceCard props={{ icon: desktop, title: 'Frontend Development' }} />
          </Col>
          <Col md={8} xs={24}>
            <ServiceCard props={{ icon: server, title: 'Backend Development' }} />
          </Col>
          <Col md={8} xs={24}>
            <ServiceCard props={{ icon: sitemap, title: 'System Architecture' }} />
          </Col>
          <Col md={8} xs={24}>
            <ServiceCard props={{ icon: chart, title: 'Project Management' }} />
          </Col>
          <Col md={8} xs={24}>
            <ServiceCard props={{ icon: brackets, title: 'Devops' }} />
          </Col>
          <Col md={8} xs={24}>
            <ServiceCard props={{ icon: setting, title: 'Operations' }} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Services
