import React from 'react'
import { Row, Col } from 'antd'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'

import Header from 'components/header'
import Footer from 'components/footer'

import Home from 'pages/home'
import Contacts from 'pages/contacts'
import NotFound from 'pages/not-found'

import './index.scss'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>

          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/contacts">
            <Contacts />
          </Route>

          <Route>
            <NotFound />
          </Route>

        </Switch>
        <Row>
          <Col span={24}>
            <Footer />
          </Col>
        </Row>
      </div>
    </Router>
  )
}

export default App
